<template>
  <div>
    <vs-row
      vs-type="flex"
      class="mt-0 mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="12"
      >
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          size="small"
          label="Data De"
          v-model="filtro.dataDe"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          label="Data Até"
          size="small"
          v-model="filtro.dataAte"
        ></vs-input>
        <el-select
          size="small"
          filterable
          clearable
          placeholder="Tipo Data"
          v-model="filtro.dataTipo"
          class="mx-1"
        >
          <el-option
            v-for="data in dataTipos"
            :value="data.id"
            :label="data.nome"
            :key="data.id"
          ></el-option>
        </el-select>
        <vs-input
          color="dark"
          class="mx-1"
          size="small"
          type="text"
          placeholder
          label="Busca"
          v-model="filtro.busca"
        ></vs-input>
        <el-select
          size="small"
          filterable
          clearable
          placeholder="Estagio"
          v-model="filtro.estagio"
          class="mx-1"
        >
          <el-option
            v-for="estagio in estagios"
            :value="estagio.id"
            :label="estagio.nome"
            :key="estagio.id"
          ></el-option>
        </el-select>
        <el-select
          filterable
          size="small"
          clearable
          placeholder="Status"
          v-model="filtro.status"
          class="mx-1"
        >
          <el-option
            v-for="data in status"
            :value="data.id"
            :label="data.id == 2 ? 'Renovação' : data.nome"
            :key="data.id"
          ></el-option>
        </el-select>
        <el-select
          filterable
          size="small"
          clearable
          placeholder="Vendedor"
          v-model="filtro.vendedor"
          class="mx-1"
        >
          <el-option
            v-for="vendedor in vendedores"
            :value="vendedor.id"
            :label="vendedor.nome"
            :key="vendedor.id"
          ></el-option>
        </el-select>
        <el-select
          filterable
          size="small"
          clearable
          placeholder="Segmento"
          v-model="filtro.segmento"
          class="mx-1"
        >
          <el-option
            v-for="segmento in segmentos"
            :value="segmento.id"
            :label="segmento.nome"
            :key="segmento.id"
          ></el-option>
        </el-select>
        <el-select
          filterable
          size="small"
          clearable
          placeholder="Faturamento"
          v-model="filtro.faturamento"
          class="mx-1"
        >
          <el-option
            v-for="faturamento in faturamentos"
            :value="faturamento.id"
            :label="faturamento.nome"
            :key="faturamento.id"
          ></el-option>
        </el-select>
        <el-select
          filterable
          size="small"
          clearable
          placeholder="Estado"
          v-model="filtro.id_estado"
          class="mx-1"
        >
          <el-option
            v-for="estado in estados"
            :value="estado.id"
            :label="estado.nome"
            :key="estado.id"
          ></el-option>
        </el-select>
        <el-button
          size="small"
          type="primary"
          class="mx-1"
          plain
          @click="getDocumentos()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
    <div id="doc_table" class="cardTable mb-1 vs-con-loading__container">
      <div v-if="documentos.length == 0">
        <div class="pt-20 scroll-documentos con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">
                Nenhum Documento
              </h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="mt-1 scroll-documentos">
        <!-- <VuePerfectScrollbar class="scroll-documentos" :settings="settings"> -->
        <vs-table
          stripe
          maxHeight="63vh"
          max-items="9"
          pagination
          :data="documentos"
        >
          <template slot="thead">
            <vs-th sort-key="numero" style="width: 5%" id="center"
              >Número</vs-th
            >
            <vs-th sort-key="cliente" style="width: 15%">Cliente</vs-th>
            <vs-th sort-key="cliente" style="width: 25%">Endereco</vs-th>
            <vs-th sort-key="status" style="width: 15%">Estágio/Status</vs-th>
            <vs-th sort-key="colaborador" style="width: 12%">Consultor</vs-th>
            <vs-th sort-key="vencimentos" style="width: 5%" id="center"
              >Vencimento</vs-th
            >
            <vs-th sort-key="data" style="width: 5%" id="center">Data</vs-th>
            <vs-th sort-key="valor" style="width: 5%" id="center">Valor</vs-th>
            <vs-th sort-key="valor_hora" style="width: 5%" id="center"
              >Valor da hora</vs-th
            >
            <vs-th>Ações</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr
              :key="index"
              v-for="(doc, index) in data"
              :style="
                doc.relatorioExists == 1
                  ? 'color: blue;background-color:#99d6ff !important;'
                  : doc.notaExists == 1
                  ? 'color: green;background-color:#e8fce8 !important;'
                  : ''
              "
            >
              <vs-td :data="doc.numero" class="text-center">
                <b>{{ doc.numero }}</b>
              </vs-td>
              <vs-td :data="doc.id_cliente">
                <VisualizarCliente
                  @update="close()"
                  :id_cliente="doc.id_cliente"
                  :nomeCliente="doc.nome_fantasia"
                />
              </vs-td>
              <vs-td :data="doc.endereco">
                {{ doc.endereco != null ? doc.endereco : "..." }}
              </vs-td>
              <vs-td>
                {{ doc.estagio }} {{ doc.tipo_faturamento }}
                <span class="font-semibold">{{
                  doc.renovado > 0 && doc.id_status != 3
                    ? " - Novo"
                    : doc.renovacao > 0 &&
                      doc.id_relacional > 0 &&
                      doc.id_status != 3
                    ? "de Renovação "
                    : `- ${doc.status}`
                }}</span>
              </vs-td>
              <vs-td class="font-semibold" style="font-size: 12px">
                {{
                  doc.nomeColaborador != null
                    ? doc.nomeColaborador
                    : "Sem consultor"
                }}
              </vs-td>
              <vs-td class="text-center">
                {{
                  doc.data_vencimento != null ? doc.data_vencimento : "Sem data"
                }}
              </vs-td>
              <vs-td class="text-center">
                {{
                  doc.data_contrato != null
                    ? doc.data_contrato
                    : doc.data_proposta != null
                    ? doc.data_proposta
                    : "Sem data"
                }}
              </vs-td>
              <vs-td class="text-center">
                {{
                  $currency(
                    parseFloat(doc.valor - doc.valor * (doc.desconto / 100))
                  )
                }}
              </vs-td>
              <vs-td class="text-center">
                {{
                  $currency(
                    parseFloat(
                      (doc.valor - doc.valor * (doc.desconto / 100)) /
                        (doc.horas_iniciais * doc.tecnicos_iniciais +
                          doc.horas_manutencao *
                            doc.tecnicos_manutencao *
                            (doc.aplicacoes - 1))
                    )
                  )
                }}
              </vs-td>
              <vs-td class="actions">
                <a
                  :href="'/contrato?id=' + doc.id"
                  style="color: inherit; text-decoration: inherit"
                >
                  <vs-button
                    icon="remove_red_eye"
                    radius
                    type="relief"
                    size="small"
                    color="success"
                  />
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- </VuePerfectScrollbar> -->
      </div>
    </div>
    <vx-card no-shadow card-background="dark">
      <vs-row>
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          class="py-1"
        >
          <Badge
            class="mr-2"
            color="dark"
            :text="'Documentos: ' + documentos.length"
            size="large"
          ></Badge>
          <Badge
            class="mx-1"
            color="dark"
            :text="'Valor total: ' + $currency(valorTotal)"
            size="large"
          ></Badge>
          <Badge
            class="mx-1"
            color="dark"
            :text="'Valor medio: ' + $currency(valorMedio)"
            size="large"
          ></Badge>
          <Badge
            class="mx-1"
            color="dark"
            :text="'Valor medio por hora: ' + $currency(valorHoraMedio)"
            size="large"
          ></Badge>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
import VisualizarCliente from "./../cliente/visualizar.vue";

export default {
  data() {
    return {
      dataTipos: [
        { id: 1, nome: "Proposta" },
        { id: 2, nome: "Contrato" },
        { id: 3, nome: "Vencimento" },
        { id: 4, nome: "Faturamento" },
        { id: 5, nome: "Renovacao" },
        { id: 6, nome: "Declinio" },
      ],
      valorMedio: null,
      valorTotal: null,
      valorHoraMedio: null,
      faturamentos: [],
      segmentos: [],
      documentos: [],
      estagios: [],
      vendedores: [],
      status: {},
      filtro: {
        faturamento: null,
        estagio: null,
        segmento: null,
        vendedor: null,
        dataDe: null,
        dataAte: null,
        dataTipo: null,
        busca: null,
        dataHoje: this.$formartData.dataFiltro(),
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
      atividades: ["4", "5", "6"],
      estados: [],
    };
  },
  async mounted() {
    await this.getDocumentos();
    await this.getSegmentos();
    await this.getEstagios();
    await this.getEstados();
    await this.getStatus();
    await this.getFaturamentos();
    await this.getVendedores();
  },
  methods: {
    async getDocumentos() {
      await this.$vs.loading({ container: "#doc_table", scale: 0.6 });
      try {
        this.documentos = await this.$http.post(`documentos`, this.filtro);
        await this.calValMed();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#doc_table > .con-vs-loading");
    },
    async calValMed() {
      let valor = 0,
        desconto = 0,
        valorHora = 0,
        denominador,
        divisor;
      for (const doc of this.documentos) {
        if (doc.valor > 0) {
          desconto = doc.valor * (doc.desconto / 100);
          valor += doc.valor - desconto;
          denominador = doc.valor - desconto;
          divisor =
            doc.horas_iniciais * doc.tecnicos_iniciais +
            doc.horas_manutencao *
              doc.tecnicos_manutencao *
              (doc.aplicacoes - 1);
          if (!(divisor > 0)) {
            divisor = 1;
          }
          valorHora += denominador / divisor;
        }
      }
      this.valorMedio = valor / this.documentos.length;
      this.valorTotal = valor;
      this.valorHoraMedio = valorHora / this.documentos.length;
    },
    async getEstagios() {
      try {
        this.estagios = await this.$http.get(`contrato_estagio`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getSegmentos() {
      try {
        this.segmentos = await this.$http.get(`segmento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getFaturamentos() {
      try {
        this.faturamentos = await this.$http.get(`tipo_faturamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getStatus() {
      try {
        this.status = await this.$http.get(`contrato_status`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getVendedores() {
      try {
        this.vendedores = await this.$http.get(`colaborador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getEstados() {
      try {
        this.estados = await this.$http.get(`get_estados_filtro`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    // async verContrato (doc) {
    //   await router.push("/contrato?id=" + doc.id)
    // }
  },
  components: { ...components, VisualizarCliente },
};
</script>
<style lang="scss">
.econtrol {
  color: #ffca00;
}
.col {
  width: auto;
}
.actions {
  width: 60px !important;
}
.scroll-documentos {
  height: 73vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
</style>
